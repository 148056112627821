import { Box, Container, Typography } from "@material-ui/core";
import Logo from "src/components/Logo";

const Maintenance = () => (
  <>
    <section className="min-item-area page-paddings">
      <div className="container">
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div className="section-title text-center">
              <Logo mode="small" />
              <h2 data-watermark="Items">Under Maintenance</h2>
              <div className="em_bar">
                <div className="em_bar_bg"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div className="user-panel-main">
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                  justifyContent: "center",
                  minHeight: "400px",
                }}
                my={4}
              >
                <Container maxWidth="md">
                  <Typography align="center" variant="h4">
                    Sorry, we are currently under maintenance. Please check back again soon!
                  </Typography>
                </Container>
              </Box>
            </div>
          </div>
        </div>
      </div>
    </section>
  </>
);

export default Maintenance;
